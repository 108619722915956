<template>
  <div class="page">
    <home-header activeIndex="1" :showNav="false" :showRight="false" :pageTitle="pageTitle"/>
    <div class="pageWrap">
      <div class="back" @click="back"><a-icon type="left" />返回</div>
      <div class="list">
        <div class="item" v-for="(item, idx) in noticeList" :key="idx" @click="toDetail(item.id)">
          <img class="icon" :src="require('@/assets/home/notice.png')" alt="" />
          <div class="con">
            <div class="tit">[{{item.nSource}}]{{item.nTitle}}</div>
          </div>
        </div>
      </div>
      <div class="pagination">
        <a-pagination v-model="page" @change="onChangePage"
        :total="total"
        :page-size="size"
        show-less-items />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import moment from 'moment'
import { getNoticeList } from '@/api/notice';
import HomeHeader from "@/components/HomeHeader";
import Footer from "@/components/footer";

export default {
  name: "Home",
  components: {
    HomeHeader,
    Footer,
  },
  data() {
    return {
      page: 1,
      size: 6,
      total: 0,
      noticeList: [],
      type: '',
      xdType: '',
      pageTitle: '',
    };
  },
  filters: {
    dateFilter(val) {
      return moment(+val).format('YYYY-MM-DD')
    }
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    toDetail(id) {
      this.$router.push({
        path: "/noticeDetail",
        query: {
          id: id,
          type: this.type,
        }
      });
    },
    onChangePage(e) {
      this.getNotice(e)
    },
    getNotice(page) {
      getNoticeList({
        nType: this.type,
        xdType: this.xdType,
        schoolCode: localStorage.getItem("schoolCode"),
        xswz: 1,
        page: page,
        size: this.size,
      }).then(res => {
        this.total = res.data.total
        this.noticeList = res.data.rows
      })
    },
  },
  mounted() {
    this.type = this.$route.query.type
    this.xdType = this.$route.query.xdType
    if (this.type == 1) {
      this.pageTitle = '报名须知'
    } else if (this.type == 2) {
      this.pageTitle = '相关政策'
    } else if (this.type == 3) {
      this.pageTitle = '通知公告'
    }
    this.getNotice(this.page)
  },
}
</script>

<style lang="less" scoped>
.pageWrap{
  width: 1000px;
	min-height: 444px;
	background-color: #ffffff;
	box-shadow: 0px 4px 10px 0px 
		rgba(0, 0, 0, 0.1);
	border-radius: 8px;
  margin: -50px auto 30px;
  position: relative;
  z-index: 2;
}
.back{
  color: #35ab61;
  padding: 20px 0 20px 32px;
  cursor: pointer;
  width: 84px;
}
.list{
  min-height: 340px;
  .item{
    display: flex;
    align-items: center;
    padding: 0 32px;
    &:hover{
      background: #f6f6f6;
      cursor: pointer;
      .con{
        .tit{
          color: #35ab61;
        }
      }
    }
    .icon{
      width: 32px;
      height: 32px;
      margin-right: 16px;
    }
    .con{
      border-bottom: solid 1px #dedede;
      padding: 18px 0;
      width: 100%;
      .tit{
        font-size: 16px;
        font-weight: 600;
        color: #333;
      }
    }
  }
}
.pagination{
  text-align: center;
  padding: 24px 0 32px;
  /deep/ .ant-pagination-item-active{
    background: #35ab61;
    a{
      color: #fff;
    }
  }
}

</style>