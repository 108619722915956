<template>
  <div class="page">
    <home-header activeIndex="1" :showNav="showNav" :showRight="showRight" :pageTitle="pageTitle"/>
    <div class="pageWrap">
      <div class="top">
        <div class="back" @click="back"><a-icon type="left" />返回</div>
        <div class="info">
          <!-- <div class="tag">发布单位：{{detail.nSource}}</div> -->
          <!-- <div class="time">{{detail.nCtime | dateFilter}}</div> -->
        </div>
      </div>
      <div class="con">
        <div class="title">{{detail.nTitle}}</div>
        <div class="content" v-html="detail.nContent"></div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import moment from 'moment'
import { getNoticeDetail } from '@/api/notice';
import HomeHeader from "@/components/HomeHeader";
import Footer from "@/components/footer";

export default {
  name: "Home",
  components: {
    HomeHeader,
    Footer,
  },
  data() {
    return {
      id: '',
      detail: {},
      pageTitle: '',
      type: '',
      showNav: '',
      showRight: '',
    };
  },
  filters: {
    dateFilter(val) {
      return moment(+val).format('YYYY-MM-DD HH:mm')
    }
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    getNotice() {
      getNoticeDetail({
        id: this.id,
      }).then(res => {
        this.detail = res.data
      })
    },
  },
  mounted() {
    this.id = this.$route.query.id
    this.type = this.$route.query.type
    if (this.type == 1) {
      this.pageTitle = '报名须知'
      this.showNav = false
      this.showRight = false
    } else if (this.type == 2) {
      this.pageTitle = '相关政策'
      this.showNav = false
      this.showRight = false
    } else if (this.type == 3) {
      this.pageTitle = '通知公告'
      this.showNav = true
      this.showRight = true
    }
    this.getNotice()
  },
}
</script>

<style lang="less" scoped>
.pageWrap{
  width: 1000px;
	min-height: 444px;
	background-color: #ffffff;
	box-shadow: 0px 4px 10px 0px 
		rgba(0, 0, 0, 0.1);
	border-radius: 8px;
  margin: -50px auto 30px;
  position: relative;
  z-index: 2;
  padding: 20px 40px;
}
.top{
  border-bottom: solid 1px #e4e4e4;
  .back{
    color: #35ab61;
    margin-bottom: 6px;
    cursor: pointer;
    width: 60px;
  }
  .info{
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: #666;
    padding: 14px 0;
  }
}
.con{
  .title{
    font-size: 22px;
    color: #333;
    font-weight: 600;
    padding: 20px 0 10px;
  }
}


</style>